import { keyframes, styled } from 'styled-components';
import { AtlasColor } from '../../foundations/Colors.foundations';
import { NeutralButtonAtom } from '../../atoms';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MenuContainer = styled.div`
  position: fixed;
  background: ${AtlasColor.Background};
  overflow: auto;
  top: 62px;
  left: 0;
  right: 0;
  padding: 16px;
  height: calc(100% - 60px);
  z-index: 1001;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const LogoutButton = styled(NeutralButtonAtom)`
  padding: 16px;
  width: 100%;
  height: 56px;
  text-transform: none;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
  gap: 24px 16px;
  width: 100%;
`;

export const GridButton = styled.button<{ $selected: boolean }>`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 8px;
  border: none;
  outline: none;
  text-align: center;
  overflow: hidden;
  background: ${({ $selected }) => ($selected ? '#FACE391F' : AtlasColor.PrimarySurface)} center;
  color: ${({ $selected }) => ($selected ? AtlasColor.Primary00 : AtlasColor.Neutral10)};
  text-transform: none;
  &:active {
    background-color: ${({ $selected }) => ($selected ? AtlasColor.Primary00 : '#2b2b2b')};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const ButtonContent = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
