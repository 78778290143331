import { NavigationMenuOrganism, PageContentAtom } from '@justomx/atlas-components'
import { UserManagment } from '@justomx/webview-commons'
import { useGoToHomePage } from '../home/home-list/HomeList.navigator'


export const MenuPage = () => {
  console.log('[rendering] MenuPage');
  const home = useGoToHomePage()
  const user = UserManagment.getCurrentUser()
  return (
      <NavigationMenuOrganism
        title={'Menu'}
        country={user.country}
        userEmail={user?.username || 'justicierx@justo.mx'}
        userName={user?.firstName || 'Justicierx'}
        version='13.0.1'
        warehouseName={user.warehouse || 'Pruebas'}
        onLogout={() => {
          UserManagment.logout();
          home.go();
        }}
        menuItems={user.menuItems || []}
        isMenuOpen={true}
      />
  )
}

