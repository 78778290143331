{
  "loader": {
    "message": "Aguarde, por favor..."
  },
  "common": {
    "continue": "Continuar"
  },
  "home": {
    "invalid-password": "Usuario o contraseña inválida",
    "invalid-qr": "Código QR inválido",
    "unknown-error": "Error desconocido",
    "user-not-found": "El usuario no existe",
    "user-login": "Usuario o contraseña incorrectos o usuario no existe",
    "user": "Correo",
    "password": "Contraseña",
    "login-with-password": "ó ingresa con correo y contraseña",
    "login-with-keycloack": "Ingresar con Keycloack",
    "login": "Ingresar"
  },
  "receipt": {
    "title": "Recibo",
    "type-error": "El tipo de datos no es válido, esperado: User"
  },
  "scanner-molecule": {
    "title": "Ingresa con código QR",
    "use-scanner": "Escanea entra a la tablet buscando tu nombre y escanea el código con tu handheld."
  },
  "select-country": {
    "title": "Selecciona el país",
    "MX": "México",
    "BR": "Brasil",
    "PE": "Perú"
  }
}