import { useState } from 'react'
import { useAtomValue } from 'jotai'
import { loginUserPasswordService, loginUserService } from '../../../services/user/user.service'
import { ErrorType } from './HomeList.types'
import { saveAndRedirectService } from '../../../services/redirect/saveAndRedirectService'
import { type LoggedUserDTO } from '@justomx/webview-commons'
import { countryAtom } from '../Home.state'
import { Analytics } from '../../../analytics/analytics.events'
import { loginUserKeycloackService } from '../../../services/user/user-keycloak.service'
import { isLoginError } from 'utils/errors/error.handler'
import { useMenuPageNavigator } from '../../menu/Menu.navigator'

function handleError(onError: (err: string, translate?: boolean) => void) {
  return (e: unknown) => {
    console.error('[HandleError]', e)
    if (isLoginError(e)) {
      onError(e.error, e.translateError)
    } else {
      onError(ErrorType.Unknown)
    }
  }
}

export const useHomeList = (onError: (err: ErrorType) => void) => {
  const country = useAtomValue(countryAtom)
  const menu = useMenuPageNavigator()
  const [loading, setLoading] = useState(false)

  function setCookiesAndRedirect(value: LoggedUserDTO) {
    console.log('Login Success', value)
    if (country) {
      saveAndRedirectService({ value, country, goToMenu: menu.go })
    } else {
      console.log('Country is UNDEFINED')
    }
  }

  function afterLogin(props: { username: string, password?: string, isQr: boolean, fn: () => Promise<LoggedUserDTO> }) {
    setLoading(true)
    Analytics.tryLogin({
      username: props.username,
      hasPassword: Boolean(props.password),
      isQr: false,
      country: country || '[NO COUNTRY]'
    })

    props.fn().then((user) => {
      setCookiesAndRedirect(user)
      Analytics.login({
        username: props.username,
        hasPassword: Boolean(props.password),
        isQr: false,
        country: country || '[NO COUNTRY]',
        warehouse: user.warehouse
      })
    }).catch(e => {
      Analytics.errorLogin({
        username: props.username,
        hasPassword: !props.isQr,
        isQr: props.isQr,
        country: country || '[NO COUNTRY]'
      }, e?.error as string)
      setLoading(false)
      handleError(onError)(e)
    })
  }

  return {
    loading,
    loginByUserPass: (username: string, password: string) => {
      if (!country) return
      if (!username || !password) return
      return afterLogin({
        fn: () => loginUserPasswordService(username, password, country),
        username: username,
        isQr: false
      })
    },
    loginWithQR: (value: string) => {
      if (!country) return
      return afterLogin({
        fn: () => loginUserService(value, country),
        username: 'qr:' + value,
        isQr: true
      })
    },
    loginWithKeycloak: (username: string, password: string) => {
      if (!country) return
      return afterLogin({
        fn: () => loginUserKeycloackService({
          username,
          password,
          country
        }),
        username,
        password,
        isQr: false
      })
    }
  }
}