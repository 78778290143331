import { translationsES } from './es/translations';
import { translationsPT } from './pt_BR/translations';

const translationByLang: Record<string, any> = {
  es: translationsES,
  pt: translationsPT,
};

export const useTranslation = (lang: string) => {
  const translations = translationByLang[lang];

  const getNestedTranslation = (obj: Record<string, any>, path: string): string | undefined => {
    const result = path
      .split('.')
      .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
    return typeof result === 'string' ? result : undefined;
  };

  const t = (key: string, params?: Record<string, string>): string => {
    const translation = getNestedTranslation(translations, key) || key;
    if (params) {
      return translation.replace(/{{(\w+)}}/g, (_: any, param: any) => params[param] || '');
    }
    return translation;
  };

  return { t };
};
