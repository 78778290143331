import { useState, useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'

import { UserManagment } from '@justomx/webview-commons'
import { countryToLanguage } from '../App'
import { useGoToCountryPage } from 'pages/home/country/Country.navigator'
import { countryAtom } from 'pages/home/Home.state'
import { saveAndRedirectService } from 'services/redirect/saveAndRedirectService'
import { useGoToHomePage } from 'pages/home/home-list/HomeList.navigator'
import { useMenuPageNavigator } from '../pages/menu/Menu.navigator'

export const useInitApp = () => {
  const country = useAtomValue(countryAtom)
  const [loading, setLoading] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)
  const countryPage = useGoToCountryPage()
  const homePage = useGoToHomePage()
  const menuPage = useMenuPageNavigator();

  const { i18n } = useTranslation('global')

  useEffect(() => {
    setIsInitialized(true)
  }, [])

  useEffect(() => {
    console.log('[useInitApp] started')
    if (isInitialized) {
      console.log('[useInitApp] isInitialized', isInitialized)
      if (!country) {
        console.log('[useInitApp] Country is UNDEFINED')
        countryPage.go()
        setLoading(false)
        return
      }
      const lang = countryToLanguage[country.toLowerCase()]
      setLang(lang)

      try {
        const loggedUser = UserManagment.getCurrentUser()

        if ('_injectedByPlugin' in window.WV && window.WV._injectedByPlugin === true) {
          console.log('[useInitApp] WV Injected by plugin')
          menuPage.go()
          setLoading(false)
          return;
        }

        console.log('[useInitApp] WV NOT Injected', Object.keys(loggedUser).length)
        if (Object.keys(loggedUser).length) {
          console.log('[useInitApp] Going to save and redirect')
          saveAndRedirectService({value: loggedUser, country, goToMenu: menuPage.go})
            .then((redirect: boolean) => {
              if(redirect) {
                console.log('[useInitApp] Going to menu page')
                menuPage.go();
                setLoading(false)
              }
              console.log('[useInitApp] Finished saving and redirect!!!!')
            })
            .catch((error) => {
              console.error('[useInitApp] Error validating current logged user', error)
            })
        } else {
          homePage.go()
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        homePage.go()
        setLoading(false)
      }
    }
  }, [country, isInitialized])

  const setLang = (lang: string) => {
    i18n.changeLanguage(lang).catch((err) => {
      console.error(err)
    })
  }

  return { loading }
}
