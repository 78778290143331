import Arrow from './Atlas/arrow.svg'
import Calendar from './Atlas/calendar.svg'
import Close from './Atlas/close.svg'
import ConsolidationLocation from './Atlas/consolidation_location.svg'
import Hamburger from './Atlas/hamburger.svg'
import IcAddIcon from './Atlas/ic_add_icon.svg'
import IcAdd24dp from './Atlas/ic_add_24dp.svg'
import IcAddAPhoto from './Atlas/ic_add_a_photo.svg'
import IcAddAPhoto1 from './Atlas/ic_add_a_photo-1.svg'
import IcAddPhoto from './Atlas/ic_add_photo.svg'
import IcAddPhotoDark from './Atlas/ic_add_photo_dark.svg'
import IcAddBox from './Atlas/ic_add_box.svg'
import IcAddCircleOutlineBlack24dp1 from './Atlas/ic_add_circle_outline_black_24dp 1.svg'
import IcAddQuantity from './Atlas/ic_add_quantity.svg'
import IcAvatar from './Atlas/ic_avatar.svg'
import IcBattery from './Atlas/ic_battery.svg'
import IcCalendar from './Atlas/ic_calendar.svg'
import IcCallBlack from './Atlas/ic_call_black.svg'
import IcCancel from './Atlas/ic_cancel.svg'
import IcChat from './Atlas/ic_chat.svg'
import IcClock from './Atlas/ic_clock.svg'
import IcCopy from './Atlas/ic_copy.svg'
import IcCountry from './Atlas/ic_country.svg'
import IcDashboard from './Atlas/ic_dashboard.svg'
import IcDeleteOutline from './Atlas/ic_delete_outline.svg'
import IcDeleteDark from './Atlas/ic_delete_dark.svg'
import IcDelete from './Atlas/ic_delete.svg'
import IcDrivers from './Atlas/ic_drivers.svg'
import IcEdit from './Atlas/ic_edit.svg'
import IcEditPrimary from './Atlas/ic_edit_primary.svg'
import IcFilterList24dp from './Atlas/ic_filter_list_24dp.svg'
import IcFlashOffBlack24dp1 from './Atlas/ic_flash_off_black_24dp 1.svg'
import IcHeightBlack from './Atlas/ic_height_black.svg'
import IcHelpOutline24dp from './Atlas/ic_help_outline_24dp.svg'
import IcHideKeyboard from './Atlas/ic_hide keyboard.svg'
import IcHome from './Atlas/ic_home.svg'
import IcHuman from './Atlas/ic_human.svg'
import IcInformation from './Atlas/ic_information.svg'
import IcInventory from './Atlas/ic_inventory.svg'
import IcKeyboard from './Atlas/ic_keyboard.svg'
import IcLayout from './Atlas/ic_layout.svg'
import IcList from './Atlas/ic_list.svg'
import IcLocalShipping from './Atlas/ic_local_shipping.svg'
import IcMenu from './Atlas/ic_menu.svg'
import IcMoreVert from './Atlas/ic_more_vert.svg'
import IcMoveDownBlack24dp1 from './Atlas/ic_move_down_black_24dp 1.svg'
import IcOrdenHija from './Atlas/ic_Orden Hija.svg'
import IcOrderChanges from './Atlas/ic_order_changes.svg'
import IcPersonBlack from './Atlas/ic_person_black.svg'
import IcPeso from './Atlas/ic_peso.svg'
import IcPesos from './Atlas/ic_Pesos.svg'
import IcPicking from './Atlas/ic_picking.svg'
import IcRecibo from './Atlas/ic_recibo.svg'
import IcRemoveCircleOutlineBlack24dp1 from './Atlas/ic_remove_circle_outline_black_24dp 1.svg'
import IcRepartitionAbasto from './Atlas/ic_repartition_abasto.svg'
import IcReport from './Atlas/ic_report.svg'
import IcReportPrimary from './Atlas/ic_report_primary.svg'
import IcScan from './Atlas/ic_scan.svg'
import IcScanQr from './Atlas/ic_scan_qr.svg'
import IcSearch24dp from './Atlas/ic_search_24dp.svg'
import IcSettings from './Atlas/ic_settings.svg'
import IcShoppingBag from './Atlas/ic_shopping_bag.svg'
import IcSignalCellular from './Atlas/ic_signal_cellular.svg'
import IcSquare from './Atlas/ic_square.svg'
import IcSubtractQuantity from './Atlas/ic_subtract_quantity.svg'
import IcSupervisor from './Atlas/ic_supervisor.svg'
import IcTarimas from './Atlas/ic_tarimas.svg'
import IcTask from './Atlas/ic_task.svg'
import IcTodaycalendar from './Atlas/ic_todaycalendar.svg'
import IcTune from './Atlas/ic_tune.svg'
import IcUbicacion from './Atlas/ic_ubicacion.svg'
import IcWarehouse from './Atlas/ic_warehouse.svg'
import IcWifiSignal from './Atlas/ic_wifi_signal.svg'
import IcZona from './Atlas/ic_zona.svg'
import InsumoEnable from './Atlas/insumo_enable.svg'
import Products from './Atlas/products.svg'
import QuestionMarkFILL0Wght400GRAD0Opsz481 from './Atlas/question_mark_FILL0_wght400_GRAD0_opsz48 1.svg'
import Scan from './Atlas/scan.svg'
import ScanDark from './Atlas/scan-dark.svg'
import Spinner from './Atlas/spinner.svg'
import IcPallet from './Atlas/ic_pallet.svg'
import IcPalletPrimary from './Atlas/ic_pallet_primary.svg'
import IcArrowBack from './Atlas/ic_arrow_back.svg'
import IcCheck from './Atlas/ic_check.svg'
import CheckCircle from './Atlas/ic_check_circle_fill.svg'
import ArrowForward from './Atlas/arrow_forward.svg'
import CheckboxChecked from './Atlas/ic_checkbutton_checked.svg'
import CheckboxUnchecked from './Atlas/ic_checkbutton_unchecked.svg'
import IcViewIcon from './Atlas/ic_view.svg'
import IcUnViewIcon from './Atlas/ic_unview.svg'
import IcQuickReorder from './Atlas/ic_quick_reorder.svg'
import IcBakery from './Atlas/ic_bakery.svg'
import IcBulk from './Atlas/ic_bulk.svg'
import icFrozen from './Atlas/ic_frozen.svg'
import icGroceries from './Atlas/ic_groceries.svg'
import icHighvalue from './Atlas/ic_highvalue.svg'
import icLaboratory from './Atlas/ic_laboratory.svg'
import icNoCategory from './Atlas/ic_no_category.svg'
import icPharmacy from './Atlas/ic_pharmacy.svg'
import icRefrigerated from './Atlas/ic_refrigerated.svg'
import IcMexico from './Atlas/ic_mexico.svg'
import IcBrazil from './Atlas/ic_brazil.svg'
import IcPeru from './Atlas/ic_peru.svg'
import IcArrowDown from './Atlas/ic_arrow_down.svg'
import IcArrowUp from './Atlas/ic_arrow_up.svg'
import IcKeyboardPrimary from './Atlas/ic_keyboard_primary.svg'
import IcUniversalSearch from './Atlas/ic_universal_search.svg'
import IcBox from './Atlas/ic_box.svg'
import IcFactory from './Atlas/ic_factory.svg'
import IcStorage from './Atlas/ic_storage.svg'
import IcLift from './Atlas/ic_lift.svg'
import IcArrowDownward from './Atlas/ic_arrow_downward.svg'
import IcReverse from './Atlas/ic_reverse.svg'
import IcPickingPrimary from './Atlas/ic_picking_primary.svg'
import IcTaskCompleted from './Atlas/ic_task_completed.svg'
import IcShrinkage from './Atlas/ic_shrinkage.svg'
import IcReportVariant from './Atlas/ic_report_variant.svg'
import IcListPrimary from './Atlas/ic_list_primary.svg'
import IcLogout from './Atlas/ic_logout.svg'
import IcKey from './Atlas/ic_key.svg'

import MenuArrow from './Atlas/Menu/menu-arrow.svg'
import MenuHand from './Atlas/Menu/menu-hand.svg'
import MenuVerified from './Atlas/Menu/menu-verified.svg'
import MenuYellowArrow from './Atlas/Menu/menu-yellow-arrow.svg'
import MenuYellowHand from './Atlas/Menu/menu-yellow-hand.svg'
import MenuYellowVerified from './Atlas/Menu/menu-yellow-verified.svg'
import MenuLogoutWhite from './Atlas/Menu/menu-logout-white.svg'

const IconsObj= {
  ArrowIcon: Arrow,
  ArrowBack: IcArrowBack,
  ArrowForward,
  CalendarIcon: Calendar,
  CloseIcon: Close,
  ConsolidationLocationIcon: ConsolidationLocation,
  IcCheck,
  CheckCircle,
  CheckboxChecked,
  CheckboxUnchecked,
  HamburgerIcon: Hamburger,
  IcAddIcon,
  IcAdd24dpIcon: IcAdd24dp,
  IcAddAPhotoIcon: IcAddAPhoto,
  IcAddAPhoto1Icon: IcAddAPhoto1,
  IcAddPhoto,
  IcAddPhotoDark,
  IcAddBoxIcon: IcAddBox,
  IcAddCircleOutlineBlack24dp1Icon: IcAddCircleOutlineBlack24dp1,
  IcAddQuantityIcon: IcAddQuantity,
  IcAvatarIcon: IcAvatar,
  IcBatteryIcon: IcBattery,
  IcCalendarIcon: IcCalendar,
  IcCallBlackIcon: IcCallBlack,
  IcCancelIcon: IcCancel,
  IcChatIcon: IcChat,
  IcClockIcon: IcClock,
  IcCopyIcon: IcCopy,
  IcCountryIcon: IcCountry,
  IcDashboardIcon: IcDashboard,
  IcDeleteOutlineIcon: IcDeleteOutline,
  IcDeleteDark,
  IcDelete,
  IcDriversIcon: IcDrivers,
  IcEditIcon: IcEdit,
  IcEditPrimary,
  IcFilterList24dpIcon: IcFilterList24dp,
  IcFlashOffBlack24dp1Icon: IcFlashOffBlack24dp1,
  IcHeightBlackIcon: IcHeightBlack,
  IcHelpOutline24dpIcon: IcHelpOutline24dp,
  IcHideKeyboardIcon: IcHideKeyboard,
  IcHomeIcon: IcHome,
  IcHumanIcon: IcHuman,
  IcInformationIcon: IcInformation,
  IcInventoryIcon: IcInventory,
  IcKeyboardIcon: IcKeyboard,
  IcLayoutIcon: IcLayout,
  IcListIcon: IcList,
  IcLocalShippingIcon: IcLocalShipping,
  IcMenuIcon: IcMenu,
  IcMoreVertIcon: IcMoreVert,
  IcMoveDownBlack24dp1Icon: IcMoveDownBlack24dp1,
  IcOrdenHijaIcon: IcOrdenHija,
  IcOrderChangesIcon: IcOrderChanges,
  IcPersonBlackIcon: IcPersonBlack,
  IcPesoIcon: IcPeso,
  IcPesosIcon: IcPesos,
  IcPickingIcon: IcPicking,
  IcReciboIcon: IcRecibo,
  IcRemoveCircleOutlineBlack24dp1Icon: IcRemoveCircleOutlineBlack24dp1,
  IcRepartitionAbastoIcon: IcRepartitionAbasto,
  IcReportIcon: IcReport,
  IcReportPrimary: IcReportPrimary,
  IcScanIcon: IcScan,
  IcScanQrIcon: IcScanQr,
  IcSearch24dpIcon: IcSearch24dp,
  IcSettingsIcon: IcSettings,
  IcShoppingBagIcon: IcShoppingBag,
  IcSignalCellularIcon: IcSignalCellular,
  IcSquareIcon: IcSquare,
  IcSubtractQuantityIcon: IcSubtractQuantity,
  IcSupervisorIcon: IcSupervisor,
  IcTarimasIcon: IcTarimas,
  IcTaskIcon: IcTask,
  IcTodaycalendarIcon: IcTodaycalendar,
  IcTuneIcon: IcTune,
  IcUbicacionIcon: IcUbicacion,
  IcWarehouseIcon: IcWarehouse,
  IcWifiSignalIcon: IcWifiSignal,
  IcZonaIcon: IcZona,
  InsumoEnableIcon: InsumoEnable,
  ProductsIcon: Products,
  QuestionMarkFILL0Wght400GRAD0Opsz481Icon: QuestionMarkFILL0Wght400GRAD0Opsz481,
  ScanIcon: Scan,
  ScanDarkIcon: ScanDark,
  SpinnerIcon: Spinner,
  IcPallet,
  IcPalletPrimary,
  IcViewIcon,
  IcUnViewIcon,
  IcQuickReorder,
  MenuArrow,
  MenuHand,
  MenuVerified,
  MenuYellowArrow,
  MenuYellowHand,
  MenuYellowVerified,
  MenuLogoutWhite,
  IcBakery,
  IcBulk,
  icFrozen,
  icGroceries,
  icHighvalue,
  icLaboratory,
  icNoCategory,
  icPharmacy,
  icRefrigerated,
  IcMexico,
  IcPeru,
  IcBrazil,
  IcArrowDown,
  IcArrowUp,
  IcKeyboardPrimary,
  IcUniversalSearch,
  IcBox,
  IcFactory,
  IcStorage,
  IcLift,
  IcArrowDownward,
  IcReverse,
  IcPickingPrimary,
  IcTaskCompleted,
  IcShrinkage,
  IcReportVariant,
  IcListPrimary,
  IcLogout,
  IcKey
} as const

export const Icons = IconsObj
