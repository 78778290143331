import {
  AtlasCaption,
  AtlasH1,
  CardComponent,
  Icon,
  Icons,
  PrimaryButtonAtom,
  TextBoxMolecule
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { FlexAtom } from 'components/atoms/Flex.atom'

interface UserAndPassProps {
  onLogin: (username: string, password: string) => void
  onLoginKeycloack: (username: string, password: string) => void
}

export function UserAndPassPage ({
  onLogin,
  onLoginKeycloack
}: UserAndPassProps) {
  const { t } = useTranslation('global')
  const [username, setUsername] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [devModeCounter, setDevMode] = React.useState<number>(0)
  const devMode = devModeCounter > 4

  const clickForDevMode = () => {
    setDevMode((e) => e + 1)
  }

  const handleLogin = () => {
    onLogin(username, password)
  }

  const handleLoginKeycloack = () => {
    onLoginKeycloack(username, password)
  }

  return (
    <>
      <FlexAtom $gap={24}>
        <FlexAtom $gap={32}>
          <AtlasH1>Inicia sesión</AtlasH1>
          <CardComponent $variant="highlight">
            <FlexAtom $direction="row" $gap={10}>
              <Icon src={Icons.IcInformationIcon} size={24} />
              <AtlasCaption onClick={clickForDevMode}>
                Se restableció tu contraseña, consulta con tu supervisor.
              </AtlasCaption>
            </FlexAtom>
          </CardComponent>
        </FlexAtom>

        <TextBoxMolecule
          tabIndex={-1}
          type="email"
          label={t('home.user')}
          onChange={(value) => {
            setUsername(value)
          }}
        />
        <TextBoxMolecule
          tabIndex={-1}
          type="password"
          label={t('home.password')}
          onChange={(value) => {
            setPassword(value)
          }}
        />
        <PrimaryButtonAtom onClick={handleLogin} type="button">
          {t('home.login')}
        </PrimaryButtonAtom>
        {devMode && (
          <PrimaryButtonAtom onClick={handleLoginKeycloack} type="button">
            {t('home.login-with-keycloack')}
          </PrimaryButtonAtom>
        )}
      </FlexAtom>
    </>
  )
}
