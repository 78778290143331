import React from 'react';

import { AtlasBodyMain, AtlasBodyTitle, AtlasCaption, Icons, StackAtom } from '../../atoms';
import { MenuItem } from '../../organisms/NavigationMenu/helpers';
import { Icon } from '../../atoms/icons/Icon';
import { useTranslation } from '../../i18n';
import {
  MenuContainer,
  GridContainer,
  GridButton,
  ButtonContent,
  LogoutButton,
} from './helper-components';

interface AppMenuProps {
  userName: string;
  userEmail: string;
  countryName: string;
  warehouseName: string;
  version: string;
  menuItems: MenuItem[];
  lang: string;
  onLogout: () => void;
  onClose?: () => void;
}

export const AppMenuMolecule = (props: AppMenuProps) => {
  const { userName, userEmail, countryName, warehouseName, version, menuItems, lang, onLogout } =
    props;
  const { t } = useTranslation(lang);

  const onSelectItem = (item: MenuItem) => {
    window.location.href = item.url;
  };

  return (
    <MenuContainer>
      <StackAtom gap={24}>
        <StackAtom gap={12}>
          <AtlasBodyTitle>{t('navMenu.greeting', { username: userName })}</AtlasBodyTitle>
          <AtlasBodyMain>{userEmail}</AtlasBodyMain>
          <AtlasCaption>
            {t('navMenu.info', {
              countryName: t(`navMenu.${countryName.toLowerCase()}`),
              warehouseName,
              version,
            })}
          </AtlasCaption>
        </StackAtom>

        <GridContainer>
          {menuItems.map((menuItem, index) => (
            <GridButton
              type="button"
              key={index}
              $selected={!!menuItem.selected}
              onClick={() => {
                onSelectItem(menuItem);
              }}
            >
              <ButtonContent>
                <Icon
                  src={(Icons as any)[menuItem.icon]}
                  color={!menuItem.selected ? 'white' : 'yellow'}
                ></Icon>
                <AtlasCaption>{t(`navMenu.${menuItem.item}`)}</AtlasCaption>
              </ButtonContent>
            </GridButton>
          ))}
        </GridContainer>
        <LogoutButton type="button" onClick={onLogout}>
          <Icon size={24} src={Icons.IcLogout} color="white" style={{ marginRight: '12px' }} />
          {t('navMenu.logout')}
        </LogoutButton>
      </StackAtom>
    </MenuContainer>
  );
};
